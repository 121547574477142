import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
// import { ParseLazyBlocks } from '../../parser'
import { LazyBlocksFactory } from '../components/LazyBlocksFactory/LazyBlocksFactory'
import TrackVisibility from 'react-on-screen'
import PromotionBannerAnimation from '../components/PromotionBannerAnimation'
import { Helmet } from 'react-helmet'

interface Props {
  data: any
  pageContext: any
}
const StandAloneTemplate = ({ data, pageContext }: Props) => {
  
  const { wpStandalone } = data
  

  const renderedBlocks = LazyBlocksFactory(wpStandalone.lazy_data, wpStandalone.title)
  // console.log(lazyBlocks)
  // console.log(renderedBlocks)

  return (
    <>
    {/* <Helmet>
    <meta name="robots" content="noindex" />
      </Helmet> */}
    <Layout>
      {renderedBlocks.map((v) => v)}

      
    </Layout>
    </>
  )
  // return <Layout>hi</Layout>
}

export default StandAloneTemplate

export const pageQuery = graphql`
  query StandaloneId($id: String!) {
    wpStandalone(id: { eq: $id }) {
      title
      id
      lazy_data
    }
  }
`
